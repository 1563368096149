import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "toastr/build/toastr.min.css";
/********Services***** */
import UserServices from "../../Services/UserService";

/*********Icons********* */
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GeneralFunctions from "../../libs/GeneralFunctions";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class ConditionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      totalElements: 0,
      page: 1,
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getUsers(value);
  }

  /***********GENERAL FUNCTIONS************* */

  updateInformationUpdated() {
    this.setState({ viewImportMetrics: false });
    this.getUsers();
  }

  getUsers(number = 1) {
    function createData(
      id,
      username,
      name,
      lastName,
      email,
      role,
      team,
      lastLogin,
      created,
      isActive
    ) {
      return {
        id,
        username,
        name,
        lastName,
        email,
        role,
        team,
        lastLogin,
        created,
        isActive,
      };
    }

    this.setState({ loading: true });
    UserServices.GetConditionsUser(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rowUsers = [];
        response.data.content.forEach((user) =>
          rowUsers.push(
            createData(
              user.id,
              user.username,
              user.name,
              user.lastName,
              user.email,
              user.role,
              user.team,
              user.lastLogin,
              user.created,
              user.isActive
            )
          )
        );
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ totalElements: response.data.totalElements });
        this.setState({ rows: rowUsers });
        this.setState({ loading: false });
      })
      .catch((error) => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-error-get-users",
            "An error occurred while trying to get the users"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  downloadConditionsUser() {
    this.setState({ loading: true });
    UserServices.downloadConditionsUser()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "LastUserLogin.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-conditions",
            "An error occurred while trying to download conditions"
          )
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, rows, numberPages, page, totalElements } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <Link to="/userPanel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t("back.label", "Back")}
                  </Button>
                </Link>

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  {t("terms-and-conditions-report.label", "Terms and Conditions Report")}
                </Button>
                
                <Button
                  className="button-table"
                  variant="contained"
                  color="secondary"
                >
                  {t("terms-and-conditions-accepted", "They accepted terms and conditions")}: {totalElements}
                </Button>
                {/*<Button
                  className="button-table ml-1 mr-1"
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadConditionsUser()}
                >
                  <CloudDownloadIcon className="mr-1" />
                  {t("menu.user-panel-button-download-csv", "Download CSV")}
                </Button>*/}

               

                

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {t(
                            "admin.header-dropdown-view-conditions-table-user",
                            "User"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {t(
                            "admin.header-dropdown-view-conditions-table-name",
                            "Name"
                          )}
                        </TableCell>

                        <TableCell align="center">
                          {t("date.label", "Date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{row.username}</TableCell>

                          <TableCell align="center">
                            {row.name + " " + row.lastName}
                          </TableCell>

                          <TableCell align="center">
                            {`${GeneralFunctions.formatNewDate(
                              row.lastLogin,
                              es
                            )}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Link to="/terms_and_conditions">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t("back.label", "Back")}
                    </Button>
                  </Link>
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    {t("terms-report.label", "Terms report")}
                  </Button>

                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(ConditionsReport)
);
