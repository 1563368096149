import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import avatarServices from "../../Services/AvatarService";
import Button from "../../../../Button";
import CropImageDialog from "../../common/Dialogs/CropImageDialog";
import { calculateSize } from "../../../../../libs/helpers";
import FileService from "../../Services/FileService";
import menu_body from "../../../../../assets/img/user_avatar.svg";
import menu_hat from "../../../../../assets/img/hat.svg";
import menu_eyes from "../../../../../assets/img/eye.svg";
import menu_mouth from "../../../../../assets/img/lips.svg";
import menu_clothes from "../../../../../assets/img/clothes.svg";
import menu_nose from "../../../../../assets/img/nose.svg";
import menu_hair from "../../../../../assets/img/hair.svg";
import menu_accessories from "../../../../../assets/img/accessories.svg";
import menu_beard from "../../../../../assets/img/beard.png";
import money from "../../../../../assets/img/money.svg";
import menu_flag from "../../../../../assets/img/flag.svg";
import mergeImages from "merge-images";
import toastr from "toastr";
import enviroment from "libs/environment";
import { TextField, makeStyles } from "@material-ui/core";
import "./styles.scss";

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      padding: '0px',
      alignItems: 'center',
      '&:hover': {
        borderColor: 'black', 
      },
      '&:focus-within': {
        borderColor: 'black', 
      },
    },
    '& .MuiInputBase-input': {
      padding: '12px 0 10px',
      textAlign: 'center',
    },
  },
});
const MAX_FILE_SIZE = 1000000;


const CustomAvatar = (props) => {
  return (
    <div className="custom-avatar-wrapper">
      <span className="avatar-custom">
        {props.flag !== null ? <img src={props.flag} alt="" /> : ""}
        {props.skin !== null ? (
          <img className="skin" src={props.skin} alt="" />
        ) : (
          ""
        )}
        {props.clothes !== null ? <img src={props.clothes} alt="" /> : ""}
        {props.mouth !== null ? <img src={props.mouth} alt="" /> : ""}
        {props.eyes !== null ? <img src={props.eyes} alt="" /> : ""}
        {props.nose !== null ? <img src={props.nose} alt="" /> : ""}
        {props.hair !== null ? <img src={props.hair} alt="" /> : ""}
        {props.hat !== null ? <img src={props.hat} alt="" /> : ""}
        {props.accessory !== null ? <img src={props.accessory} alt="" /> : ""}
        {props.beard !== null ? <img src={props.beard} alt="" /> : ""}
      </span>
    </div>
  );
};

const Avatar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sesion, setSesion] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastname] = useState("");
  const [file, setFile] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);
  const [openImageCrop, setOpenImageCrop] = useState(null);
  const [urlAvatar, setUrlAvatar] = useState(null);
  const [creatingAvatar, setCreatingAvatar] = useState(false);
  const [AvatarParts, setAvatarParts] = useState({
    skins: undefined,
    clothes: undefined,
    mouths: undefined,
    eyes: undefined,
    noses: undefined,
    hairs: undefined,
    hats: undefined,
    accessories: undefined,
    flags: undefined,
    beards: undefined,
  });

  const [partSelected, setPartSelected] = useState("SKIN");

  const [parts, setParts] = useState(null);

  const handleChange = (name, newPart) => {

    if (!fetching)
      switch (name) {
        case "SKIN":
          setAvatarParts((state) => {
            return { ...state, skins: newPart };
          });
          break;
        case "EYE":
          setAvatarParts((state) => {
            return { ...state, eyes: newPart };
          });
          break;
        case "MOUTH":
          setAvatarParts((state) => {
            return { ...state, mouths: newPart };
          });
          break;
        case "BODY":
          setAvatarParts((state) => {
            return { ...state, clothes: newPart };
          });
          break;
        case "NOSE":
          setAvatarParts((state) => {
            return { ...state, noses: newPart };
          });
          break;
        case "HAIR":
          setAvatarParts((state) => {
            return { ...state, hairs: newPart };
          });
          break;
        case "ACCESSORY":
          setAvatarParts((state) => {
            return { ...state, accessories: newPart };
          });
          break;
        case "HAT":
          setAvatarParts((state) => {
            return { ...state, hats: newPart };
          });
          break;
        case "FLAG":
          setAvatarParts((state) => {
            return { ...state, flags: newPart };
          });
          break;
        case "BEARD":
          setAvatarParts((state) => {
            return { ...state, beards: newPart };
          });
          break;
        default:
          return "";
      }
  };
  useEffect(() => {
    let userSesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(userSesion);
    setName(userSesion.user.name);
    setLastname(userSesion.user.lastName);

    if (userSesion.user.avatarParts !== undefined)
      for (let i = 0; i < userSesion.user.avatarParts.length; i++)
        handleChange(
          userSesion.user.avatarParts[i].type,
          userSesion.user.avatarParts[i]
        );
  
    avatarServices
      .getParts()
      .then((resp) => {
        setParts(resp.data.content);
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, []);
  
  const handleChangePart = (name) => {
    setCreatingAvatar(true);
    setUrlAvatar(null);

    setPartSelected(name);
  };

  function toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }

  const allToB64 = (images, callback) => {
    let count = 0;
    let img = new Array(images.length);

    var a = function () {
      count++;
    };

    var countReturned = function () {
      return count;
    };

    for (let i = 0; i < images.length; i++) {
      if (images[i] !== undefined) {
        toDataURL(images[i], function (dataUrl) {
          img[i] = new Image();
          img[i].src = dataUrl;
          img[i].onload = function () {
            a();
            if (countReturned() === images.length)
              callback(
                img
                  .filter((value) => value.src !== "")
                  .map((value) => value.src)
              );
          };
        });
      } else {
        count++;
        img[i] = { src: "" };
      }
    }
  };

  const handleArchieve = (fileToSave) => {
    const MAX_WIDTH = 1200;
    const MAX_HEIGHT = 1200;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    setFetching(true);

    const blobURL = window.URL.createObjectURL(fileToSave);

    const img = new Image();
    img.src = blobURL;

    img.onload = function () {
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("CANVAS");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          if (!blob || blob.size > MAX_FILE_SIZE) {
            toastr.error(
              t(
                "admin.mission-user-file-size-error",
                "File must be 3Mb size or less."
              )
            );
            return;
          }

          const formData = new FormData();
          formData.append("file", blob, fileToSave.name);
          FileService.uploadFile(formData, "mission_user")
            .then((response) => {
              setUrlAvatar(response.data.url);
              let new_sesion = sesion;
              new_sesion.user.image = response.data.url;
              setSesion(new_sesion);
            })
            .catch((error) => {
              toastr.error(t("admin.mission-message-error", "Fatal error."));
            })
            .finally(() => {
              setFetching(false);
            });
        },
        MIME_TYPE,
        QUALITY
      );
    };
  };

  function getImageFromDialog(img, urlimg) {
    let aux = img;
    setFile(aux);
    setOpenCropImage(false);
  }

  const inputFile = useRef(null);

  const onButtonClick = () => {
    
    inputFile.current.click();
  };

  const handleFile = (event) => {
    let fileSelected = event.target.files[0];

    if (fileSelected.type.split("/")[0] + "" === "image") {
      setOpenCropImage(true);
      setOpenImageCrop(event.target.files[0]);
    } else {
      setFile(fileSelected);
    }
  };

  useEffect(() => {
    if (file != null) {
      handleArchieve(file);
      return;
    }
  }, [file]);

  const mergeAvatar = (images, callBack) => {
    setFetching(true);

    if(urlAvatar == null && creatingAvatar) {
      allToB64(images, (data) => {
        mergeImages(data).then((b64) => {
          callBack(b64);
        });
      });
    } else {
      callBack('');
    }
  };

  const updateUser = (data) => {
    if (sesion.user.coinBalance - getCoins() >= 0) {
      setFetching(true);
      avatarServices
        .updateUser(data)
        .then((resp) => {
          localStorage.setItem(
            "sesion",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("sesion")),
              user: {
                ...resp.data,
              },
            })
          );
          window.location.reload();
          setFetching(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400 && error.response.data && error.response.data.message && error.response.data.message.includes("Duplicate entry")) {
            toastr.error("El usuario ya existe");
          } else {
            toastr.error(
              `${
                error.response != null &&
                error.response.data != null &&
                error.response.data.message != null
                  ? error.response.data.message
                  : "Ha ocurrido un error en el guardado."
              }`
            );
          }
          setFetching(false);
        });
    } else {
      toastr.error("No tienes suficientes monedas.");
    }
  };
  
  

  const getCoins = () => {
    let count = 0;
    if (AvatarParts.skins !== undefined)
      if (!AvatarParts.skins.isFree && AvatarParts.skins.isMine === false) {
        count += AvatarParts.skins.coins;
      }
    if (AvatarParts.clothes !== undefined)
      if (!AvatarParts.clothes.isFree && AvatarParts.clothes.isMine === false) {
        count += AvatarParts.clothes.coins;
      }
    if (AvatarParts.mouths !== undefined)
      if (!AvatarParts.mouths.isFree && AvatarParts.mouths.isMine === false) {
        count += AvatarParts.mouths.coins;
      }
    if (AvatarParts.eyes !== undefined)
      if (!AvatarParts.eyes.isFree && AvatarParts.eyes.isMine === false) {
        count += AvatarParts.eyes.coins;
      }
    if (AvatarParts.hairs !== undefined)
      if (!AvatarParts.hairs.isFree && AvatarParts.hairs.isMine === false) {
        count += AvatarParts.hairs.coins;
      }
    if (AvatarParts.flags !== undefined)
      if (!AvatarParts.flags.isFree && AvatarParts.flags.isMine === false) {
        count += AvatarParts.flags.coins;
      }
    
    if (AvatarParts.beards !== undefined)
      if (!AvatarParts.beards.isFree && AvatarParts.beards.isMine === false) {
        count += AvatarParts.beards.coins;
      }

    if (AvatarParts.hats !== undefined)
      if (!AvatarParts.hats.isFree && AvatarParts.hats.isMine === false) {
        count += AvatarParts.hats.coins;
      }
    if (AvatarParts.accessories !== undefined)
      if (
        !AvatarParts.accessories.isFree &&
        AvatarParts.accessories.isMine === false
      ) {
        count += AvatarParts.accessories.coins;
      }
    return count;
  };

  useEffect(() => {
    let userSesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(userSesion);

    if (userSesion.user.avatarParts !== undefined)
      for (let i = 0; i < userSesion.user.avatarParts.length; i++)
        handleChange(
          userSesion.user.avatarParts[i].type,
          userSesion.user.avatarParts[i]
        );

    avatarServices
      .getParts()
      .then((resp) => {
        setParts(resp.data.content);
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, []);

  if (sesion)
    return (
      <div className="avatar-page-wrapper" style={{paddingTop: props.player == undefined ? '80px' : '0px'}}>
        <div className="sum-points">
          <div style={{ display: "flex", gap: "10px" }}>
            {sesion && sesion.user && (
              <TextField
                label="Nombre"
                className={classes.root}
                style={{ marginBottom: '2px' }}
                variant="outlined"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  style: {
                    borderColor: 'blue', 
      borderColor: 'blue', 
                    borderColor: 'blue', 
                  },
                }}
              />
            )}

            {sesion && sesion.user && (
              <TextField
                label="Apellido"
                className={classes.root}
                style={{ marginBottom: '2px' }}
                variant="outlined"
                type="text"
                value={lastName}
                onChange={(e) => setLastname(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="avatar-custom-control">
          <br></br>
          {creatingAvatar ? (
            <CustomAvatar
              skin={
                AvatarParts.skins !== undefined
                  ? AvatarParts.skins.id !== null
                    ? `${enviroment.motivarnosBackend}` + AvatarParts.skins.image
                    : AvatarParts.skins.image
                  : null
              }
              hat={
                AvatarParts.hats !== undefined
                  ? AvatarParts.hats.id !== null
                    ? `${enviroment.motivarnosBackend}` + AvatarParts.hats.image
                    : AvatarParts.hats.image
                  : null
              }
              clothes={
                AvatarParts.clothes !== undefined
                  ? AvatarParts.skins.id !== null
                    ? `${enviroment.motivarnosBackend}` +
                      AvatarParts.clothes.image
                    : AvatarParts.clothes.image
                  : null
              }
              mouth={
                AvatarParts.mouths !== undefined
                  ? AvatarParts.mouths.id !== null
                    ? `${enviroment.motivarnosBackend}` + AvatarParts.mouths.image
                    : AvatarParts.mouths.image
                  : null
              }
              eyes={
                AvatarParts.eyes !== undefined
                  ? AvatarParts.eyes.id !== null
                    ? `${enviroment.motivarnosBackend}` + AvatarParts.eyes.image
                    : AvatarParts.eyes.image
                  : null
              }
              nose={
                AvatarParts.noses !== undefined
                  ? AvatarParts.noses.id !== null
                    ? `${enviroment.motivarnosBackend}` + AvatarParts.noses.image
                    : AvatarParts.noses.image
                  : null
              }
              hair={
                AvatarParts.hairs !== undefined
                  ? AvatarParts.hairs.id !== null
                    ? `${enviroment.motivarnosBackend}` + AvatarParts.hairs.image
                    : AvatarParts.hairs.image
                  : null
              }
              accessory={
                AvatarParts.accessories !== undefined
                  ? AvatarParts.accessories.id !== null
                    ? `${enviroment.motivarnosBackend}` +
                      AvatarParts.accessories.image
                    : AvatarParts.accessories.image
                  : null
              }
              flag={
                AvatarParts.flags !== undefined
                  ? AvatarParts.flags.id !== null
                    ? `${enviroment.motivarnosBackend}` +
                      AvatarParts.flags.image
                    : AvatarParts.flags.image
                  : null
              }
              beard={
                AvatarParts.beards !== undefined
                  ? AvatarParts.beards.id !== null
                    ? `${enviroment.motivarnosBackend}` +
                      AvatarParts.beards.image
                    : AvatarParts.beards.image
                  : null
              }
            />
          ) : (
            <div className="custom-avatar-wrapper" style={{alignItems: 'baseline'}}>
              <div className="avatar-custom">
                <img src={`${enviroment.motivarnosBackend}${sesion.user.image}`} alt="" style={{height: '20.6em'}}/>
              </div>
            </div>
          )}
          <div className="avatar-items-control">
            <ul>
              <li
                className={
                  "pointer" + (partSelected === "SKIN" ? " part-active" : "")
                }
                onClick={() => handleChangePart("SKIN")}
              >
                <img src={menu_body} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "EYE" ? " part-active" : "")
                }
                onClick={() => handleChangePart("EYE")}
              >
                <img src={menu_eyes} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "MOUTH" ? " part-active" : "")
                }
                onClick={() => handleChangePart("MOUTH")}
              >
                <img src={menu_mouth} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "BODY" ? " part-active" : "")
                }
                onClick={() => handleChangePart("BODY")}
              >
                <img src={menu_clothes} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "NOSE" ? " part-active" : "")
                }
                onClick={() => handleChangePart("NOSE")}
              >
                <img src={menu_nose} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "HAIR" ? " part-active" : "")
                }
                onClick={() => handleChangePart("HAIR")}
              >
                <img src={menu_hair} alt="" />
              </li>
              <li
                className={
                  "pointer" +
                  (partSelected === "ACCESSORY" ? " part-active" : "")
                }
                onClick={() => handleChangePart("ACCESSORY")}
              >
                <img src={menu_accessories} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "HAT" ? " part-active" : "")
                }
                onClick={() => handleChangePart("HAT")}
              >
                <img src={menu_hat} alt="" />
              </li>
              <li
                className={
                  "pointer" + (partSelected === "FLAG" ? " part-active" : "")
                }
                onClick={() => handleChangePart("FLAG")}
              >
                <img src={menu_flag} alt="" />
              </li>

              <li
                className={
                  "pointer" + (partSelected === "BEARD" ? " part-active" : "")
                }
                onClick={() => handleChangePart("BEARD")}
              >
                <img src={menu_beard} alt="" />
              </li>
            </ul>
          </div>
          <div style={{display: 'flex', marginTop: '10px'}}>
            <Button
              onClick={() => {
                mergeAvatar(
                  [
                    AvatarParts.flags !== undefined
                      ? AvatarParts.flags.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.flags.image
                        : AvatarParts.flags.image
                      : undefined,
                    AvatarParts.skins !== undefined
                      ? AvatarParts.skins.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.skins.image
                        : AvatarParts.skins.image
                      : undefined,
                    AvatarParts.clothes !== undefined
                      ? AvatarParts.skins.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.clothes.image
                        : AvatarParts.clothes.image
                      : undefined,
                    AvatarParts.mouths !== undefined
                      ? AvatarParts.mouths.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.mouths.image
                        : AvatarParts.mouths.image
                      : undefined,
                    AvatarParts.eyes !== undefined
                      ? AvatarParts.eyes.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.eyes.image
                        : AvatarParts.eyes.image
                      : undefined,
                    AvatarParts.noses !== undefined
                      ? AvatarParts.noses.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.noses.image
                        : AvatarParts.noses.image
                      : undefined,
                    AvatarParts.hairs !== undefined
                      ? AvatarParts.hairs.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.hairs.image
                        : AvatarParts.hairs.image
                      : undefined,
                    AvatarParts.accessories !== undefined
                      ? AvatarParts.accessories.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.accessories.image
                        : AvatarParts.accessories.image
                      : undefined,
                    AvatarParts.hats !== undefined
                      ? AvatarParts.hats.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.hats.image
                        : AvatarParts.hats.image
                      : undefined,
                    AvatarParts.beards !== undefined
                      ? AvatarParts.beards.id !== null
                        ? `${enviroment.motivarnosBackend}` +
                          AvatarParts.beards.image
                        : AvatarParts.beards.image
                      : undefined,
                  ],
                  (data) => {
                    let auxParts = [
                      AvatarParts.flags,
                      AvatarParts.skins,
                      AvatarParts.clothes,
                      AvatarParts.mouths,
                      AvatarParts.eyes,
                      AvatarParts.noses,
                      AvatarParts.hairs,
                      AvatarParts.accessories,
                      AvatarParts.hats,
                      AvatarParts.beards,
                    ];

                    updateUser({
                      id: sesion.user.id,
                      name: name,
                      lastName: lastName,
                      image: urlAvatar == null ? (creatingAvatar ? data : sesion.user.image) : urlAvatar,
                      avatarParts: [
                        ...auxParts
                          .filter((value) => {
                            return value !== undefined && value.id !== null;
                          })
                          .map((value) => {
                            return { id: value.id };
                          }),
                      ],
                    });
                  }
                );
              }}
              disabled={parts === null || fetching}
            >
              {t("confirm.label")}
            </Button>
            <Button onClick={onButtonClick} style={{marginLeft: '30px'}}>
              Cargar Avatar
            </Button>
            <input onChange={handleFile} type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
            <CropImageDialog
              aspect={0.9}
              open={openCropImage}
              openImageCrop={openImageCrop}
              getImageFromDialog={(img, urlimg) => getImageFromDialog(img, urlimg)}
            />
          </div>
        </div>
        <div className="parts-items-container">
          {parts === null ? (
            ""
          ) : (
            <ul className="avatar-parts-container">
              {[...parts].map((data, index) => {
                if (data.type === partSelected || data.type === "")
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        handleChange(partSelected, data);
                      }}
                      style={{
                        filter: fetching ? "saturate(0)" : "saturate(1)",
                      }}
                    >
                      <span className="avatar-part">
                        {data.image.includes("/media/") ? (
                          <img src={data.image} alt="" />
                        ) : (
                          <img
                            src={`${enviroment.motivarnosBackend}` + data.image}
                            alt=""
                          />
                        )}
                      </span>
                      {!data.isFree && !data.isMine ? (
                        <span className="price">
                          <span className="money">
                            <img src={money} alt="" />
                          </span>
                          <span>{data.coins}</span>
                        </span>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
        </div>
      </div>
    );
  else return "";
};

export default Avatar;
